const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],
    activeProduct: [],
    allActiveProducts: [],
    // change_PasswordData:[],
    activeModel:[]
  }

  
  const productSubCategoryReducer = (state = initialState, action) => {
      
      
    switch (action.type) { 
      case 'GET_DATA_PRODUCT_SUB_CATEGORY':
        return {
          ...state,
          allData: action.allData,
          data: action.data,
          total: action.totalPages,
          params: action.params
        }
      case 'GET_DATA_ALL_ACITVE_PRODUCT_CATEGORY':
        return {
          ...state,
          activeProduct: action.data,
        }
      case 'GET_ACTIVE_PRODUCTS':
        return {
          ...state,
          allActiveProducts: action.data,
        }
  
      case 'CREATE_PRODUCT_SUB_CATEGORY':
        return { ...state }
      case 'UPDATE_PRODUCT_SUB_CATEGORY':
        return { ...state }
    //   case 'DELETE_PRODUCT':
    //     return { ...state }
  
    //   case 'GET_DATA_ALL_ACITVE_MODEL':
    //     return {
    //       ...state,
    //       activeModel: action.data,
    //     }
  
  
      default:
        return { ...state }
    }
  }

  
  export default productSubCategoryReducer;
  